import React, { useState } from "react"
import { RichText } from "prismic-reactjs"
import isEqual from "lodash/isEqual"

import {
  Textbox,
  Button,
  CustomSelect,
  FormValidator,
  htmlSerializerUpdateStyle,
  NotificationToast,
  setLocalStorage,
  MEMBERSHIP_INFO,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  data: Object,
  handleShowNZOffer: () => void,
|}

const MembershipInfo = ({ data = {}, handleShowNZOffer }: Props) => {
  const [error, setError] = useState()
  const [gym, setGym] = useState()
  const [membershipNumber, setMembershipNumber] = useState("")
  const {
    membership_number_label = {},
    gyms_label = {},
    body1 = [],
    start_now_button_label = {},
  } = data

  const [gyms = {}] = body1
  const fields = (gyms.items || []).map(item => ({
    label: item.name,
    value: item.name,
  }))
  const handleOnClick = () => {
    setLocalStorage(MEMBERSHIP_INFO, { gym, membershipNumber })
    handleShowNZOffer()
  }

  // https://lesmillsinternational.atlassian.net/browse/LA-1126
  // prevent typing non-numeric and longer than 21 digits
  // also show error messages
  const handleOnChangeMemberShipNumber = e => {
    const value = e.target.value
    const err = FormValidator(["CODE", "MEMBERSHIP_NUMBER"], value, {
      invalidError: data.invalid_membership_number,
      limitError: data.limit_membership_number,
    })

    setError(err)

    if (!err) {
      setMembershipNumber(value)
    }
  }

  const disabledButton = !membershipNumber || !gym

  return (
    <section
      className="mx-auto xl:max-w-920 max-w-95/100 flex flex-wrap justify-between mt-50"
      data-cy="membership-info"
    >
      {error && (
        <NotificationToast
          showHideIcon
          type="error"
          classNames={{ wrapper: " mb-30" }}
          children={
            <RichText
              render={error.raw}
              htmlSerializer={htmlSerializerUpdateStyle(
                "",
                "text-white font-base-medium leading-20 text-base"
              )}
            />
          }
          handleHideToast={() => setError(null)}
        />
      )}
      <CustomSelect
        label={gyms_label.text}
        options={fields}
        handleOnSelect={value => setGym(value)}
        currentValue={gym}
        classNames={{
          label:
            "custom-select-label bg-white absolute px-2 z-10 text-base-light md:text-base text-xs leading-20 text-gray-500",
          wrapper: ` md:w-32/100 w-full font-base-light text-gray-800 text-base leading-20`,
          selectedItem: ` focus:outline-none custom-select dropdown border border-gray-800 w-full md:h-textbox-lg h-textbox-base flex items-center`,
          list: " bg-white z-10 w-full",
          listItem: `w-full md:h-textbox-lg h-textbox-base flex items-center`,
          innerListItem: "ml-20",
        }}
        tabIndex={1}
      />
      <Textbox
        label={membership_number_label.text}
        classNames={{
          textbox:
            "border-gray-800 w-full md:h-textbox-lg h-textbox-base font-base-light text-gray-800 text-base leading-20",
          label: "md:text-base text-xs",
          wrapper: "mt-20 md:mt-0 md:w-32/100 w-full",
          innerWrapper: error ? "error" : "",
        }}
        id="membership-number"
        handleOnChange={handleOnChangeMemberShipNumber}
        value={membershipNumber}
        testId="membership-number"
      />
      <Button
        className="uppercase text-white bg-orange-400 md:w-32/100 w-full bg-orange-400 hover:opacity-75 cursor-pointer text-3xl leading-20 font-primary md:h-textbox-lg h-textbox-base md:mb-20 mb-10"
        handleOnClick={handleOnClick}
        disabled={disabledButton}
        testId="start-now-btn"
      >
        {start_now_button_label.text}
      </Button>
    </section>
  )
}

export default React.memo(MembershipInfo, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
