import React from "react"
import isEqual from "lodash/isEqual"

import { RichText } from "prismic-reactjs"

import {
  htmlSerializerUpdateStyle,
  Button,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  data: Object,
  logo: Object,
  handleClickYes: () => void,
  handleClickNo: () => void,
  isShowMemberInfo?: Boolean,
|}

const Hero = ({
  data = {},
  logo = {},
  handleClickYes,
  handleClickNo,
  isShowMemberInfo,
}: Props) => {
  const {
    yes_button_label = {},
    no_button_label = {},
    hero_question = {},
    hero_title = {},
  } = data || {}

  const hero_image = data.hero_image || {}
  const hero_image_mobile = data.hero_image_mobile || {}

  return (
    <section className="relative h-video-section" data-cy="hero">
      <div className="absolute md:top-26/100 top-25 w-full">
        <div className="xl:max-w-920 md:max-w-90/100 max-w-95/100 mx-auto">
          <img
            className="md:w-logo-lmod-base w-white-logo-small ml-10 md:ml-0"
            alt="Logo"
            title="Les Mills on Demand"
            src={logo.url}
            data-cy="logo"
          />
          <RichText
            render={hero_title.raw}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "",
              "",
              "",
              "",
              "",
              undefined, // This function was called after image was loaded
              "_self",
              "",
              "",
              "text-white font-primary text-6xl uppercase leading-32 md:text-xxl md:leading-45 md:mt-25 md:mb-22 mt-90 mb-175 text-center md:text-left md:max-w-375 max-w-295 mx-auto md:mx-0"
            )}
          />
          <RichText
            render={hero_question.raw}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "md:text-white md:text-2lg text-3xs text-gray-800 md:mb-23 mb-32 font-helvetica-light md:text-left text-center"
            )}
          />
          <Button
            className={`uppercase text-white md:w-btn-md w-full hover:opacity-75 cursor-pointer text-3xl leading-20 font-primary py-15 mr-15 md:mb-20 mb-10 ${
              isShowMemberInfo ? "bg-orange-500" : "bg-orange-400"
            }`}
            handleOnClick={handleClickYes}
          >
            {yes_button_label.text}
          </Button>
          <Button
            className="uppercase text-white bg-orange-400 md:w-btn-md w-full bg-orange-400 hover:opacity-75 cursor-pointer text-3xl leading-20 font-primary py-15 mr-15 md:mb-20 mb-10"
            handleOnClick={handleClickNo}
          >
            {no_button_label.text}
          </Button>
        </div>
      </div>
      <picture>
        <source srcSet={hero_image.url} media="(min-width: 640px)" />
        <source srcSet={hero_image_mobile.url} media="(max-width: 640px)" />
        <img
          alt={hero_image.alt}
          src={hero_image.url}
          className="w-full object-cover md:h-full h-360  bg-black"
          data-cy="hero-img"
        />
      </picture>
    </section>
  )
}

export default React.memo(Hero, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.data, nextProps.data) &&
    prevProps.isShowMemberInfo === nextProps.isShowMemberInfo &&
    isEqual(prevProps.handleClickNo, nextProps.handleClickNo)
  )
})
