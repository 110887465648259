import React from "react"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"

import { htmlSerializerUpdateStyle } from "@lesmills/gatsby-theme-common"

import Program from "../GettingStarted/Programs/Program"

type Props = {|
  data: object,
|}

const Featuring = ({ data }: Props) => {
  const { primary = {}, items = [] } = data || {}

  return (
    <section
      className="mx-auto xl:max-w-920 my-50 max-w-95/100"
      data-cy="featuring"
    >
      <RichText
        render={primary.featuring}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "",
          "",
          "",
          "",
          "",
          undefined, // This function was called after image was loaded
          "_self",
          "",
          "",
          "font-primary text-6xl text-3xl uppercase leading-24 text-center text-gray-800"
        )}
      />
      <div className="flex flex-wrap w-full justify-between md:mt-30 mt-20">
        {items.map((item, index) => (
          <Program
            program={{
              image: item.featuring_image,
            }}
            key={index + "-featuring"}
            handleOpenPopup={() => {}}
          />
        ))}
      </div>
    </section>
  )
}

export default React.memo(Featuring, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
