import React, { useState } from "react"
import loadable from "@loadable/component"

import type { MembershipPageType } from "../types/MembershipPageType"
import type { UserType, AllLayoutsType } from "@lesmills/gatsby-theme-common"
import isEqual from "lodash/isEqual"
import AllSubscriptionsInclude from "../AllSubscriptionsInclude"
import Hero from "../Hero"
import FrequentlyAskedQuestionsSection from "../FrequentlyAskedQuestionsSection"

import {
  formatBenefits,
  handleSaveProduct,
  getSessionStorage,
  CURRENT_COUNTRY,
} from "@lesmills/gatsby-theme-common"

import { AXII_CONFIGS } from "../../../../../src/configs/axii-config"

const Subscriptions = loadable(() => import("@lesmills/gatsby-theme-common"), {
  resolveComponent: components => components.Subscriptions,
})
const Notification = loadable(() => import("@lesmills/gatsby-theme-common"), {
  resolveComponent: components => components.Notification,
})

const Equipment = loadable(() => import("../Equipment"))

type Props = {|
  data: MembershipPageType,
  lang: string,
  user: UserType,
  layout?: AllLayoutsType,
  isNZOffers?: Boolean,
  location?: object,
  productList?: Object,
|}

const Membership = ({
  data,
  lang,
  user = {},
  layout = {},
  isNZOffers = false,
  location = {},
  productList = {},
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [currentCountryCode, setCurrentCountryCode] = useState()
  const [updateUserError, setUpdateUserError] = useState()
  const {
    hero_description = {},
    hero_image = {},
    hero_video = {},
    hero_title = {},
    plan_notes = {},
    body = [],
    start_now_button_label = {},
    promo_expired_error_message = {},
  } = data || {}

  const { les_mills_plus_logo } = layout || {}

  const [all_subscriptions = {}, faqs = {}] = body
  const benefits = all_subscriptions.items || []
  const questions = faqs.items || []
  const state = location.state || {}

  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1133
  // Get user country from session storage
  const countryCode = getSessionStorage(CURRENT_COUNTRY) || currentCountryCode
  // Check user country code include equipment bundles countries(US & UK)
  const isEquipmentBundlesCountries = AXII_CONFIGS.equipmentBundlesCountries.includes(
    countryCode
  )

  //  Turn on Equipment section with exact address and turn on equipment equal true
  const turnOnEquipment =
    data &&
    data[`${countryCode}_turn_on_equipment`] === true &&
    isEquipmentBundlesCountries === true
  // Push data layer event when payload and when start now
  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-952

  return (
    <>
      <Hero
        heroImg={hero_image}
        heroVideo={hero_video}
        logo={les_mills_plus_logo}
        description={hero_description}
        title={hero_title.text}
        lang={lang}
        user={user}
        location={location}
        currentCountryCode={currentCountryCode}
      />
      {state.isPromoExpired ? (
        <Notification
          type="error"
          message={promo_expired_error_message.text}
          classNames={{
            wrapper: "w-80/100 justify-center mx-auto mt-30 ",
          }}
        />
      ) : null}
      {state.errorCode ? (
        <Notification
          type="error"
          message={data[state.errorCode].text}
          classNames={{
            wrapper: "w-80/100 justify-center mx-auto mt-30 ",
          }}
        />
      ) : null}
      {updateUserError ? (
        <Notification
          type="error"
          message={updateUserError}
          classNames={{
            wrapper: "w-80/100 justify-center mx-auto mt-30 ",
          }}
        />
      ) : null}
      <Subscriptions
        planNotes={plan_notes}
        btnLabel={start_now_button_label.text}
        classNames={{
          wrapper: "lg:max-w-1233 md:max-w-750 md:pt-80 md:pb-23 py-45",
          subscriptions: "justify-center subscription-membership",
          item: "w-9/10 md:w-30/100 md:ml-12 md:mr-12 md:mb-35 mb-60",
        }}
        handleClickStartNow={product => {
          product = {
            ...product,
            ...(isNZOffers && {
              isNZOffers,
              offerId: product.tune_offer_id,
              affiliateId: product.tune_affiliate_id,
            }),
          }

          handleSaveProduct(
            user,
            product,
            setLoading,
            lang,
            isNZOffers, // isAffiliate
            false,
            err => {
              err && setUpdateUserError(layout[err.errorType].text)
            }
          )

          // Push event when click start now
        }}
        isNZOffers={isNZOffers}
        isOnMembership
        prismicData={data}
        processing={loading}
        layoutData={layout}
        getCurrentCountryCode={countryCode => {
          setCurrentCountryCode(countryCode)
        }}
        productList={productList}
      />
      {turnOnEquipment && (
        <Equipment prismicData={data} countryCode={countryCode} />
      )}
      <AllSubscriptionsInclude
        benefits={formatBenefits(benefits)}
        title={
          all_subscriptions.primary &&
          all_subscriptions.primary.all_subscriptions_title.text
        }
      />
      <FrequentlyAskedQuestionsSection
        questions={questions}
        title={faqs.primary && faqs.primary.faq_title.text}
      />
    </>
  )
}

export default React.memo(Membership, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.data, nextProps.data) &&
    isEqual(prevProps.user, nextProps.user) &&
    prevProps.isNZOffers === nextProps.isNZOffers
  )
})
