import React, { useState, memo, useEffect } from "react"
import isEqual from "lodash/isEqual"
import type { MembershipPageType } from "../types/MembershipPageType"

import {
  getSessionStorage,
  setSessionStorage,
  CURRENT_COUNTRY,
  isBrowser,
  publicAPI,
  getListProducts,
  Spinner,
  handleErrorWithPrismic,
  Notification,
  isIAP,
  ROUTES,
} from "@lesmills/gatsby-theme-common"

import Membership from "../Membership"
import MemberDiscount from "../../../../gatsby-theme-public-pages/src/components/MemberDiscount"
import { navigate } from "gatsby"

type Props = {|
  memberDiscountData: MembershipPageType,
  membershipData: Object,
  lang: string,
  user: UserType,
  layout: AllLayoutsType,
  location?: Object,
|}

const MembershipPage = ({
  memberDiscountData,
  membershipData,
  lang,
  layout,
  user,
  location,
}: Props) => {
  const currentCountry = getSessionStorage(CURRENT_COUNTRY)
  const [isNZDiscount, setIsNZDiscount] = useState(currentCountry === "nz")
  const [isNZOffers, setIsNZOffers] = useState(false)
  const [productList, setProductList] = useState({})
  const [error, setError] = React.useState(null)

  const setProducts = data => {
    const countryCode = data.data.data.getValidProducts.countryCode.toLowerCase()
    setIsNZDiscount(countryCode === "nz")
    setSessionStorage(CURRENT_COUNTRY, countryCode)
    setProductList(data.data)
  }

  const handleError = err => {
    handleErrorWithPrismic(
      err,
      membershipData.lmod_lpbc_err && membershipData.lmod_lpbc_err.text,
      setError,
      membershipData
    )
  }

  useEffect(() => {
    if (!currentCountry) {
      publicAPI(getListProducts, setProducts, handleError)
    }
  }, [])

  const pushUrlToHistory = () => {
    if (isBrowser) {
      window.history.pushState({}, "", window.location.href)
    }
  }

  const scrollToTop = () => {
    isBrowser && window.scrollTo(0, 0)
  }

  const handleShowNZOffer = () => {
    setIsNZDiscount(false)
    setIsNZOffers(true)
    scrollToTop()
    pushUrlToHistory()
  }

  return currentCountry ? (
    isNZDiscount ? (
      <MemberDiscount
        data={memberDiscountData}
        layout={layout}
        handleClickNo={() => {
          if (isIAP(user)) {
            navigate(ROUTES(lang).CUSTOMER_ACCOUNT)
          } else {
            isBrowser &&
              (window.location.href = process.env.GATSBY_LANDING_PAGES_HOME)
          }
        }}
        handleShowNZOffer={handleShowNZOffer}
      />
    ) : (
      <Membership
        data={membershipData}
        lang={lang}
        user={user}
        layout={layout}
        isNZOffers={isNZOffers}
        location={location}
        productList={productList}
      />
    )
  ) : (
    <div className="mt-50 flex justify-center">
      {error ? (
        <Notification message={error.message} type="error" />
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default memo(MembershipPage, (prevProps, nextProps) => {
  return isEqual(prevProps.user, nextProps.user)
})
