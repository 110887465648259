import React from "react"
import isEqual from "lodash/isEqual"
import LazyLoad from "react-lazyload"

import type { ImageType } from "@lesmills/gatsby-theme-common"

type Props = {|
  program: {
    image: ImageType,
  },
  handleOpenPopup: () => void,
|}

const Program = ({ program, handleOpenPopup }: Props) => {
  const { image = {}, id = "id" } = program || {}

  const handleOnClick = React.useCallback(() => handleOpenPopup(id), [id])

  return (
    <>
      <div
        className="max-w-49/100 md:max-w-24/100 lg:max-w-16/100 mb-4 cursor-pointer"
        onClick={handleOnClick}
        data-cy={"program" + "-" + id}
      >
        <LazyLoad once height="h-full">
          <img className="w-full" src={image.url} alt={image.alt} />
        </LazyLoad>
      </div>
    </>
  )
}

export default React.memo(Program, (prevProps, nextProps) => {
  isEqual(prevProps.program, nextProps.program)
})
