import * as React from "react"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"
import {
  linkResolver,
  htmlSerializerUpdateStyle,
  FrequentlyAskedQuestions,
} from "@lesmills/gatsby-theme-common"

import type { Body } from "../../types/FAQPageType"

type Props = {
  data: Body,
  backToTopBtn: React.Node,
  noBackToTop: boolean,
  classNames: Object,
}

const QuestionList = ({
  data,
  backToTopBtn,
  noBackToTop,
  classNames = {},
}: Props) => {
  const { primary = {}, items = [] } = data || {}
  const id = primary.id ? primary.id.text : ""

  const formatedFAQ = items.map((item, index) => ({
    question: item.question,
    answer: item.answers,
    id: `${id}-${index}`,
    ...(item.start_trial && {
      lastChild: (
        <div className="start-trial my-30">
          <RichText render={item.start_trial.raw} linkResolver={linkResolver} />
        </div>
      ),
    }),
  }))

  return (
    <section className="w-full" data-cy={`faq-${id}`}>
      <div className="bg-gray-30 w-full" id={id}>
        <div className="max-w-960 bg-transparent py-40 px-20 mx-auto faq-header relative">
          <RichText
            render={primary.title && primary.title.raw}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "",
              "",
              "",
              "",
              "",
              undefined, // This function was called after image was loaded
              "_self",
              "",
              "",
              classNames.title ||
                "uppercase font-primary text-gray-920 md:text-3xl text-base"
            )}
          />
        </div>
      </div>
      <FrequentlyAskedQuestions
        questions={formatedFAQ}
        classNames={{
          main: {
            item: {
              title: "py-40",
              wrapper: "w-full bg-gray-450 px-20",
              accordion:
                " bg-transparent max-w-960 mx-auto border-b-2 border-gray-110 faq",
              selectedWrapper: "w-full bg-gray-210 px-20",
              icon: " triangle-icon md:p-25 p-15 bg-gray-560 relative",
            },
          },
          answers: classNames.answer,
          children: "text-white font-base-light text-2xs mb-26 md:text-2lg",
          question:
            classNames.question ||
            "uppercase font-primary text-white md:text-3xl text-base",
        }}
        icon="triangle-up"
      />
      {!noBackToTop && (
        <div className="bg-black w-full">
          <div className="max-w-960 bg-transparent py-40 px-20 mx-auto flex justify-center">
            {backToTopBtn}
          </div>
        </div>
      )}
    </section>
  )
}

export default React.memo(QuestionList, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
