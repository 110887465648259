import React, { useEffect } from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import type { MembershipPageType } from "../types/MembershipPageType"
import {
  CURRENT_COUNTRY,
  getSessionStorage,
  isBrowser,
  UserType,
} from "@lesmills/gatsby-theme-common"

import {
  renderSEO,
  Layout,
  PublicRoute,
  ROUTES,
  DATA_LAYER_SCRIPT,
} from "@lesmills/gatsby-theme-common"

import Membership from "../components/MembershipPage"

type Props = {|
  pageContext: {
    lang: string,
  },
  data: MembershipPageType,
  location: Object,
|}

const MembershipPage = (props: Props) => {
  const { pageContext, data, location } = props
  const scriptsMembershipPage = [DATA_LAYER_SCRIPT]

  const lang = pageContext.unPublishedLang || pageContext.lang
  if (!data) return null

  const {
    prismicLayout = {},
    prismicMemberDiscountPage = {},
    prismicMembershipPage = {},
  } = data

  useEffect(() => {
    const isClientSide = isBrowser && window.location
    const countryCode = getSessionStorage(CURRENT_COUNTRY)

    // Redirect non-NZ users to landing page
    if (
      isClientSide &&
      countryCode &&
      countryCode !== "nz" &&
      lang &&
      lang !== "en-nz"
    ) {
      window.location.href = process.env.GATSBY_LANDING_PAGES_HOME
    }
  }, [lang])

  const layout = prismicLayout.data || {}
  const pageContent = prismicMembershipPage.data || {}
  const memberDiscountData = prismicMemberDiscountPage.data || {}

  const renderMembershipPage = ({ user, checkingSession }: UserType) => (
    <Layout
      data={layout}
      headerless
      lang={lang}
      user={user}
      isLoading={checkingSession}
    >
      {renderSEO(
        pageContent,
        ROUTES(lang).MEMBERSHIP,
        lang,
        scriptsMembershipPage
      )}
      <Membership
        user={user}
        memberDiscountData={memberDiscountData}
        membershipData={pageContent}
        layout={layout}
        lang={lang}
        location={location}
      />
    </Layout>
  )

  return <PublicRoute component={renderMembershipPage} {...props} lang={lang} />
}

export const query = graphql`
  query membershipQuery($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      data {
        lmod_uace_err {
          text
        }
        logo {
          alt
          url
        }
        les_mills_plus_logo {
          alt
          url
        }
        sign_in_button {
          raw
          text
        }
        sign_out_button {
          raw
          text
        }
        best_value {
          raw
          text
        }
        ...LayoutUnits
        body1 {
          ...LayoutBody1Navigation
        }
      }
    }
    prismicMembershipPage(lang: { eq: $lang }) {
      data {
        promotion_signed_link_invalid {
          text
        }
        promotion_signed_link_expired {
          text
        }
        promotion_signed_link_invalid_country {
          text
        }
        promo_expired_error_message {
          text
        }
        after_trial {
          raw
          text
        }
        annual_interval {
          raw
          text
        }
        billed_price {
          raw
          text
        }
        body {
          ... on PrismicMembershipPageBodyAllSubscriptions {
            items {
              subscription_description {
                raw
                text
              }
              subscription_icon {
                alt
                url
              }
            }
            primary {
              all_subscriptions_title {
                raw
                text
              }
            }
          }
          ... on PrismicMembershipPageBodyFaqs {
            items {
              answer {
                raw
              }
              question {
                raw
              }
            }
            primary {
              faq_title {
                text
              }
            }
          }
        }
        cancel_future_any_time {
          raw
          text
        }
        daily_interval {
          raw
          text
        }
        equipment_description {
          raw
          text
        }
        equipment_title {
          raw
          text
        }
        free_trial {
          raw
          text
        }
        gb_equipment_photo {
          url
          alt
          thumbnails {
            mobile {
              url
              alt
            }
          }
        }
        gb_equipment_url {
          url
          raw
        }
        gb_turn_on_equipment
        hero_description {
          raw
          text
        }
        hero_image {
          alt
          url
        }
        hero_video {
          raw
        }
        hero_title {
          raw
          text
        }
        lmod_gp_err {
          text
        }
        lmod_lpbc_err {
          text
        }
        monthly_interval {
          text
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_image {
          url
          alt
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        percent_off_monthly_price {
          raw
          text
        }
        plan_notes {
          raw
          text
        }
        start_now_button_label {
          raw
          text
        }
        subscription_unit {
          text
        }
        us_equipment_photo {
          alt
          url
        }
        us_equipment_url {
          url
        }
        us_turn_on_equipment
      }
    }
    prismicMemberDiscountPage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicMemberDiscountPageBodyFeaturing {
            items {
              featuring_image {
                alt
                url
              }
            }
            primary {
              featuring {
                raw
                text
              }
            }
          }
          ... on PrismicMemberDiscountPageBodyFaq {
            items {
              faq_answer {
                raw
                text
              }
              faq_question {
                raw
                text
              }
            }
            primary {
              faq_title {
                raw
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicMemberDiscountPageBody1Gyms {
            items {
              name
            }
          }
        }
        gyms_label {
          raw
          text
        }
        hero_image {
          alt
          url
        }
        hero_image_mobile {
          alt
          url
        }
        hero_question {
          raw
          text
        }
        hero_title {
          raw
          text
        }
        invalid_membership_number {
          raw
          text
        }
        limit_membership_number {
          raw
          text
        }
        lmod_um_err {
          text
        }
        membership_number_label {
          raw
          text
        }
        no_button_label {
          raw
          text
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        start_now_button_label {
          raw
          text
        }
        yes_button_label {
          raw
          text
        }
      }
    }
  }
`

export default withPreview(MembershipPage)
